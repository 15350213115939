import React, { ComponentProps } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Header } from 'components/Header'
import { Layout } from 'components/Layout'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { Error404Root, Wrapper, AnimationWrapper, ContentWrapper } from './Error404.styles'

type Error404OwnProps = ComponentProps<typeof Error404Root>
export type Error404Props = Error404OwnProps & {}

export const Error404 = (_props: Error404Props) => {
  const { t } = useTranslation()

  const header = <Header />

  return (
    <Error404Root>
      <Layout header={header} hideCTA>
        <Stack justifyContent="center">
          <Wrapper>
            <AnimationWrapper>
              <object
                type="image/svg+xml"
                data={`/assets/animation-sniffing-dog.svg`}
                aria-label={t('common:error-404.subtitle-2')}
                aria-hidden
              />
            </AnimationWrapper>

            <ContentWrapper>
              <Stack direction="column" gap={{ '@initial': '4', '@bp2': '6' }}>
                <Stack
                  direction="column"
                  gap="0"
                  justifyContent="center"
                  alignItems={{ '@initial': 'center', '@bp2': 'flex-start' }}
                >
                  <h1>{t('common:error-404.title')}</h1>
                  <Stack
                    direction="column"
                    gap="2"
                    alignItems={{ '@initial': 'center', '@bp2': 'flex-start' }}
                  >
                    <Stack
                      direction="column"
                      alignItems={{ '@initial': 'center', '@bp2': 'flex-start' }}
                    >
                      <Text
                        size={{ '@initial': 'h4', '@bp2': 'h2' }}
                        weight="bold"
                        color="accent-black"
                      >
                        {t('common:error-404.subtitle-1')}
                      </Text>
                      <Text
                        size={{ '@initial': 'h4', '@bp2': 'h2' }}
                        as="h2"
                        weight="bold"
                        color="accent-black"
                      >
                        {t('common:error-404.subtitle-2')}
                      </Text>
                    </Stack>
                    <Text
                      size={{ '@initial': 'body', '@bp2': 'headline' }}
                      color="accent-black"
                      align={{ '@initial': 'center', '@bp2': 'left' }}
                    >
                      {t('common:error-404.description')}
                    </Text>
                  </Stack>
                </Stack>
                <Button as="a" href="/">
                  {t('common:error-404.cta')}
                </Button>
              </Stack>
            </ContentWrapper>
          </Wrapper>
        </Stack>
      </Layout>
    </Error404Root>
  )
}

Error404.displayName = 'Error404'
