import React from 'react'
import { NextPageContext } from 'next'
import { Error404 } from 'features/components/Error404'
import * as Sentry from '@sentry/nextjs'

interface ErrorProps {
  statusCode: number
  hasGetInitialPropsRun: boolean
  err: Error
}

const CustomError = ({ statusCode, err }: ErrorProps): JSX.Element => {
  return <Error404 />
}

CustomError.getInitialProps = async (context: NextPageContext) => {
  const { res, err } = context
  const errorInitialProps: ErrorProps = {
    statusCode: res?.statusCode || (err && err.statusCode) || 500,
    hasGetInitialPropsRun: true,
    err: err as Error,
  }

  if (err) {
    Sentry.captureException(err)
    await Sentry.flush(2000)
    return errorInitialProps
  }

  return errorInitialProps
}

export default CustomError
