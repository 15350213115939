import { styled } from 'stitches.config'

export const Error404Root = styled('div', {
  backgroundColor: '$tertiary-200',
})

export const Wrapper = styled('div', {
  minHeight: '100vh',
  maxWidth: '$max',
  display: 'flex',
  flexDirection: 'column',
  padding: '$3',
  paddingTop: '$6',

  '@bp1': {
    padding: '$6',
  },
  '@bp2': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '$9',
  },
  '@bp3': {
    width: '1200px',
  },
  '@bp4': {
    width: '$max',
  },
})

export const AnimationWrapper = styled('div', {
  display: 'block',
  flex: 1,
  '@bp2': {
    flex: 2,
  },
})

export const ContentWrapper = styled('div', {
  flex: 1,

  '& h1': {
    fontSize: '80px',
    fontWeight: '$semiBold',
    color: '$accent-black',

    '@bp2': {
      fontSize: '150px',
    },
  },
})
